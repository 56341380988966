.edit-course-page .card {
  width: 320px;
  vertical-align: top;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.edit-course-page .downloading {
  margin-right: 8px;
}
