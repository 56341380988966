.users-page .both button.floating-share {
    right: 72px;
}

.wide .users-page .both button.floating-share {
    left: calc(50% - 56px);
}

.users-page .both button.floating-add {
    right: 8px;
}

.wide .users-page .both button.floating-add {
    left: calc(50% + 8px);
}
