.add-document-dialog .MuiDivider-root {
    margin: 30px -24px 20px -24px ;
}

.add-document-dialog .file {
    margin-top: 10px;
}

.add-document-dialog .dnd-file {
    border-radius: 20px;
    height: 100px;
    width: 220px;
    padding: 0 5px;
    margin: 10px auto;
    text-align: center;
    cursor: pointer;
}

.light .add-document-dialog .dnd-file {
    border: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #F0F8FF;
}

.dark .add-document-dialog .dnd-file {
    border: 1px solid rgba(255, 255, 255, 0.08);
    background-color: #2A2A2A;
}
