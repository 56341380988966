.icon {
    height: 64px;
    width: 64px;
    border-radius: 8px;
    vertical-align: middle;
    background: linear-gradient(180deg, #5FAFDC 0%, #0F52BA 100%);
    margin: 0 auto;
}

.icon img.image {
    height: 56px;
    width: 56px;
}
