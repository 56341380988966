.user-card .MuiDivider-root {
    margin: 15px -24px 20px -24px;
}

.user-card td.MuiTableCell-root {
    border-style: none !important;
}

.user-card .role {
    float: right;
    margin-top: -28px;
}

.user-card .ask {
    margin-top: 10px;
    font-weight: bolder !important;
}
