.documents-card .MuiDivider-root {
    margin: 10px -24px;
}

.documents-card td {
    padding-right: 0;
}

.documents-card .timestamp {
    white-space: nowrap;
}

.MuiCard-root.documents-card {
    max-width: 600px;
}

.documents-card .file {
    min-width: 200px;
    max-width: 320px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.documents-card img.course {
    height: 100px;
    border: 1px solid #777;
    cursor: pointer;
}

.documents-card table.striped {
    width: 100%;
}

.documents-card table .download {
    width: 56px;
}
